.br-header.compact .header-logo {
    display: none;
    margin-bottom: 4px;
}

.br-header.compact .br-divider {
    height: 24px;
}

.br-header.compact {
    box-shadow: none;
}

.br-header .header-menu,
.br-header .header-menu .header-title {
    display: flex;
    align-items: center;
}

.br-header .header-menu .header-title,
.br-header .header-menu .header-title a {
    font-weight: var(--font-weight-light);
    font-size: 25.63px;
    color: var(--pure-0) !important;
}

.br-header .header-menu .header-title a {
    background: transparent;
    transition: background 0.2s ease-in-out;
}

.br-header .header-menu .header-title a:hover {
    background-color: #2a3a53;
}

.br-header.compact .header-actions {
    margin-top: 5px;
}

.br-header.compact .br-divider.vertical {
    align-self: auto;
}

.br-header.compact .subtitle {
    font-size: 12px;
}

.br-header.compact .br-item [class*="fa-"] {
    width: 20px !important;
}

.br-header.compact {
    padding: 12px 0;
}

.br-header.compact .br-button.large {
    width: 44px !important;
    height: 44px !important;
}

.br-header.compact .br-button#btSearch.large,
.br-header.compact .br-button#btCloseSearch.large {
    width: 36px !important;
    height: 36px !important;
}

.br-header.compact .header-functions.dropdown.show .br-list .br-item .br-button.large {
    width: 100% !important;
    height: 100% !important;
}

.br-header.compact .header-actions .header-functions .br-item,
.br-header.compact .header-actions .header-functions .br-item:not(:last-child) {
    margin-right: 0;
}

.br-header.compact .header-actions .header-functions.dropdown>.br-button,
.br-header.compact .header-actions .header-functions .br-item:last-child {
    margin: 0 8px;
}

.br-header.compact .br-button,
.br-header.compact .header-actions .header-functions .br-list .br-button {
    color: var(--blue-warm-vivid-20);
}

.br-header.compact .br-button.primary {
    color: var(--pure-100);
    background: var(--blue-warm-vivid-20);
    transition: background 0.2s ease-in-out;
}

.br-header.compact .br-button.primary:hover {
    background: #c6dbfc;
}

.br-header.compact .br-button.primary i {
    font-size: 16px;
}

.br-header.compact .br-button {
    background: transparent;
    transition: background 0.2s ease-in-out;
}

.br-header.compact .br-button:hover {
    background-color: #2a3a53;
}

.br-header.compact,
.br-header.compact .br-list,
.br-header.compact .header-search.active,
.br-header.compact .header-search.active,
.br-header.compact .header-actions .header-links .br-list,
.br-header.compact .header-actions .header-functions .br-list,
.br-header.compact .dropdown .br-item {
    background: var(--blue-warm-80);
}

.br-header.compact {
    min-height: 76px;
}

.br-header.compact {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
}

.br-header.compact.sticky {
    box-shadow: var(--surface-shadow-sm);
}

#main {
    margin-top: 76px;
}

.tooltip-ds {
    color: var(--pure-0) !important;
    background-color: var(--blue-warm-vivid-60) !important;
    opacity: 1 !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
    z-index: 999;
}

.search-box-container .ais-SearchBox input {
    width: 100%;
    height: 56px;
    background-color: var(--gray-2);
    border-color: transparent;
    padding-left: var(--spacing-scale-6x);
    padding-right: var(--spacing-scale-3x);
    border-radius: var(--surface-rounder-sm);
    border-style: var(--border-style);
    border-width: var(--border-width);
    color: var(--color-light);
    display: block;
    font-size: var(--font-size-scale-up-01);
    font-weight: var(--font-weight-medium);
    margin-top: var(--spacing-scale-half);
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: var(--spacing-scale-10x);
    border: 2px solid var(--gray-10);
}

.search-box-container .ais-SearchBox input:focus,
.search-box-container .ais-SearchBox input:focus-visible {
    border-color: var(--focus) !important;
    box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
    outline: none;
    border: 1px solid;
}

.search-box-container i {
    color: var(--border-color-alternative);
    position: relative;
    top: 44px;
    left: 16px;
}

.br-header .br-tag {
    min-height: 24px;
    padding: 2px 8px;
    font-size: var(--font-size-scale-down-01);
}

.br-header .header-search.active {
    margin-right: 24px;
}

.br-header .br-list .header {
    color: var(--pure-0);
}

.bt-img-hugo {
    margin-left: -7px;
}

.img-dark-theme {
    display: none;
}



@media (max-width: 992px) {

    .br-header .header-menu .header-title,
    .br-header .header-menu .header-title a {
        font-size: 20.25px;
    }

    .br-header .header-menu .header-menu-trigger+.header-info {
        margin-left: 0;
    }
}

@media (max-width: 600px) {
    .br-header.compact .header-actions .header-functions.dropdown>.br-button {
        margin-right: 0;
        margin-left: 0;
    }

    #btSearch.ml-3 {
        margin-left: 4px !important;
    }
}

@media (max-width: 576px) {

    .br-header .header-menu .header-title,
    .br-header .header-menu .header-title a {
        font-size: 18px;
    }

    .br-header .header-top {
        display: inline-block;
        float: right;
    }

    .br-header .header-bottom {
        display: inline-block;
    }

    .br-header .header-search.active {
        margin-right: 0;
        margin-left: 0;
    }

    .header-functions.dropdown.show .br-list {
        left: -160px;
    }
}