.info-panel {
    margin-bottom: 15px;
}

.info-text {
    font-size: 16px;
}

.linha {
    display: flex;
    flex-direction: column;
}

.info-tratamento {
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.link {
    display: flex;
    justify-content: flex-end;
}