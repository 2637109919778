.br-footer.inverted {
    background-color: var(--pure-0);
}

.br-footer .logo .info,
.br-footer .logo .info,
.br-footer .logo {
    text-align: center;
}

.br-footer .info {
    margin-top: auto;
}

.br-footer .info div,
.br-footer .info div a {
    font-size: 14.22px;
}

.br-footer .info div a {
    background: transparent;
    transition: background 0.2s ease-in-out;
}

.br-footer .info div a:hover {
    background: #e6ecf5;
}

footer.br-footer .logo img {
    width: 100px;
}

footer.br-footer .br-divider {
    border-style: dashed;
}

@media (max-width: 992px) {
    footer.br-footer .info {
        text-align: center;
        font-size: 14.22px;
    }
}