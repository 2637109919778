.splash-banner {
    height: 60%;
    margin: auto;
    animation: bannerAnimation ease 2s;
    animation-iteration-count: 1;
}

.splash-btn {
    width: 50%;
    margin: auto;
    animation-delay: 1s;
    animation: loginAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes bannerAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    from {
        top: -150px;
    }

    to {
        top: 0px;
    }
}

@keyframes loginAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    from {
        top: 100px;
    }

    to {
        top: 0px;
    }
}