.list-container {
  margin-bottom: 20px;
}

.expand-button {
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.list {
  list-style-type: none;
  padding: 0;
}

.list li {
  margin-bottom: 8px;
}

.card-title {
  display: flex;
  justify-content: space-between;
}

.warning-after::after {
  content: " ⚠";
  font-size: 14px;
  color: orange;
}

.warning-before::before {
  content: "⚠ ";
  font-size: 14px;
  color: orange;
}