.panelCard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 0.333fr));
  grid-gap: 24px;
  padding-bottom: 32px;
}

.card {
  padding: 26px 16px 16px;
  border-radius: 6px;
  background: transparent;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
}

.card:hover {
  background: #e6ecf5;
}

.card img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 16px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.card p {
  font-size: 16px;
  font-weight: var(--font-weight-regular);
}

.card h4,
.home .card h4 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 16px;
  font-weight: var(--font-weight-regular);
  font-size: 28.83px;
}

.main-content .card a {
  text-decoration: none;
}

.card:hover img {
  transform: translateY(-10px);
}

.card.disabled:hover img {
  transform: none;
  box-shadow: none;
}

@media (max-width: 992px) {
  .panelCard {
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.5fr));
  }

  .row.justify-center-flex {
    justify-content: center;
  }
}

@media (max-width: 634px) {
  .panelCard {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .panelCard .card p,
  .panelCard .card h4 {
    text-align: center;
  }

  .panelCard .card h4 {
    font-size: 25.23px;
  }
}