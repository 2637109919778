.card-panel-hz {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
}

.clickable {
    cursor: pointer;
}

.mb-1n {
    margin-bottom: -1px;
}

.mb-1 {
    margin-bottom: 1px;
}

.br-menu .menu-item.selected {
    background-color: var(--color-secondary-03);
}

.listcard-container {
    display: grid;
    grid-template-columns: minmax(20rem, 30%) auto;
    gap: 1rem;
}

.footnote {
    font-size: 14x;
    font-style: italic;
}

.box-style {
    background-color: '#ececec';
    border-width: 1;
    border-radius: 5;
    padding: 10px;
}

@media (max-width: 1000px) {
    .listcard-container {
        grid-template-columns: 100%;
        justify-content: center;
    }
}

@media (max-width: 1290px) {
    .card-panel-hz {
        flex-direction: column;
        text-align: center;
    }
}