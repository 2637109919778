.toast-message {
    background: grey;
    color: #fff;
    font-size: 14px;
    padding: 20px 10px;
    width: 500px;
}

.toast-info {
    background: #155bcb;
    color: #fff;
}

.toast-success {
    background: #168821;
    color: #fff;
}

.toast-error {
    background: #e52207;
    color: #fff;
}

.toast-warning {
    background: #f59f00;
    color: #fff;
}

.toast-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    height: 24px;
}

.close-icon {
    top: 0;
    right: 0;
    color: #FFFFFF;
    height: 10px;
    cursor: pointer;
}

.Toastify__toast-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}