.br-menu .menu-item {
    font-size: medium;
    color: #1351B4;
}

.br-menu .menu-panel {
    max-width: 0%;
    transition: max-width 0.15s;
}

.br-menu .menu-panel.active {
    max-width: 100%;
}

.br-menu.active .menu-panel {
    padding-top: 18px;
    padding-left: 8px;
}

.br-menu.active .menu-container.pushable {
    height: 100vh;
}

@media (min-width: 720px) {
    .br-menu.active .menu-container.pushable {
        top: unset;
        bottom: unset;
        position: absolute;
    }

    .br-menu .menu-panel.active {
        max-width: 33%;
    }

    .br-menu .menu-footer {
        display: none;
    }

    .menu-header .user-area {
        display: none;
    }
}